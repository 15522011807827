<template>
	<div class="send">
		<MainVisual />
		<div class="sendContent">
			<Step class="threeStep" />
			<div class="redCareful">
				※予約は完了していません、下記にお進みください※
			</div>
			<section class="formSelect">
				<h3 class="formSelect_ttl mincho">ご予約内容の確認</h3>
				<p class="formSelect_txt">お申し込みの前に「@asahi-internet.com」のドメインについて受信可能な設定に変更をお願いいたします。設定により、メールが届かない場合がございます。その際はご連絡願います。</p>
			</section>
			<form action="/" method="post" class="sendForm">
				<ul class="inputLists">
					<li class="inputList" v-if="selectEvent">
						<p class="inputList_ttl">ご予約日時</p>
						<div class="inputDate inputList_cont">
							<p class="inputDate_date">{{ selectYear }}年{{ selectMonth }}月{{ selectDate }}日({{ selectDay }})</p>
							<p class="inputDate_time">{{ retakeDate(selectedTime) }}</p>
						</div>
						<p class="inputList_cont" v-html="selectEvent.rsv_course_name"></p>
					</li>
					<li class="inputList" v-if="paidInfo">
						<p class="inputList_ttl">料金</p>
						<p class="inputList_cont" v-html="price"></p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">ご予約人数</p>
						<div class="peopleBox">
							<div class="peopleMore">
								<p class="peopleMore_ttl" v-if="non_alcohol_flg == 0">20歳以上飲酒を希望</p>
								<p class="peopleMore_ttl" v-else>20歳以上</p>
								<p class="peopleMore_people">{{ alcohol_num }}人</p>
							</div>
							<div class="peopleBelow" v-show="non_alcohol_flg == 0">
								<p class="peopleBelow_ttl">20歳以上飲酒を希望しない</p>
								<p class="peopleBelow_less">{{ non_alcohol_num }}人</p>
							</div>
							<div class="peopleBelow" v-show="minor_flg == true">
								<p class="peopleBelow_ttl">20歳未満</p>
								<p class="peopleBelow_less">{{ minor_num }}人</p>
							</div>
						</div>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">代表者名</p>
						<p class="inputList_cont">{{ group_name }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">代表者名（ふりがな）</p>
						<p class="inputList_cont">{{ group_name_kana }}</p>
					</li>
					<li class="inputList" v-if="companion_flg">
						<p class="inputList_ttl">同伴者様</p>
						<p class="inputList_cont">{{ companion_name }}</p>
					</li>
					<li class="inputList" v-if="companion_flg">
						<p class="inputList_ttl">同伴者様（ふりがな）</p>
						<p class="inputList_cont">{{ companion_name_kana }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">ご住所</p>
						<p class="inputList_cont">{{ prefName }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">お電話番号</p>
						<p class="inputList_cont">{{ tel }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">メールアドレス</p>
						<p class="inputList_cont">{{ email }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">ご来場回数</p>
						<p class="inputList_cont">{{ countNum }}</p>
					</li>
					<li class="inputList">
						<p class="inputList_ttl">ご来場手段</p>
						<p class="inputList_cont">{{ visitWay }}</p>
					</li>
					<li class="inputList" v-if="unit != null && unit != 'null'">
						<p class="inputList_ttl">ご来場台数</p>
						<p class="inputList_cont">{{ unit }}台</p>
					</li>
					<li class="inputList" v-if="selectCourseInfo[0].chk_allergy_flg == 1">
						<p class="inputList_ttl">アレルギー有無確認</p>
						<p class="inputList_cont" v-show="allergy == 0">アレルギー無し</p>
						<p class="inputList_cont" v-show="allergy == 1">アレルギー有り</p>
					</li>
					<!-- <li class="inputList" v-if="mygflg == false && note != null && note != 'null' && note != ''">
						<p class="inputList_ttl">備考</p>
						<pre class="inputList_cont inputList_note">{{ note }}</pre>
					</li> -->
				</ul>
				<div class="lastCheck">
					<ul class="lastCheck_lists">
						<li>※複数のご予約を頂いているお客様は、確認のため事前にご連絡させていただく場合がございます。</li>
						<li>※セミナー予約は当日キャンセルの場合、100％のキャンセル料を頂戴致しますのでご了承ください。</li>
					</ul>
					<p class="lastCheck_txt" v-show="mygflg == true">宮城峡蒸溜所<span class="lastCheck_txt_small">（宮城県仙台市）</span><br class="sp">のご予約を確定します。よろしいですか？</p>
					<p class="lastCheck_txt" v-show="mygflg == false">余市蒸溜所<span class="lastCheck_txt_small">（北海道余市郡）</span><br class="sp">のご予約を確定します。よろしいですか？</p>
					<div class="btns">
						<button type="button" class="backBtn" @click="backInput()">入力内容を変更する</button>
						<button type="button" class="sendBtn" @click="sendForm()">予約を確定する</button>
					</div>
				</div>
			</form>
		</div>
		<Tel />
		<div class="loadingBack" v-show="anim_flg">
			<div id="lottie" ref="lottie"></div>
		</div>
		<div class="faildModal" v-show="faild_flg">
			<p class="faildModal_ttl">通信に失敗しました</p>
			<p class="faildModal_txt">電波状況の良いところで再度ご確認ください</p>
			<button type="button" class="faildModal_btn" @click="closeFaild()">閉じる</button>
		</div>
		<div class="reservedModal" v-show="reserved_flg">
			<p class="reservedModal_ttl">ご予約済みです</p>
			<p class="reservedModal_txt">同一メールアドレス同一コースや<br class="pc">セミナー同時刻で、すでにご予約いただいております。</p>
			<button type="button" class="reservedModal_btn" @click="closeReserved()">閉じる</button>
		</div>
		<div class="faildBack" v-show="modal_flg" @click="closeModal()"></div>
	</div>
</template>

<script>
import MainVisual from './MainVisual'
import Step from './Step'
import Tel from './Tel'
import axios from 'axios'
import lottie from 'lottie-web'
import animationData from "@/assets/anim/nikka_loading_231016_2.json"

export default {
	name: 'RservationThree',
	components: {
		MainVisual,
		Step,
		Tel
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			mygflg: false,
			plantName: null,
			prefData: JSON.parse(localStorage.getItem('prefData')),
			visitNum: JSON.parse(localStorage.getItem('visitNum')),
			comeWay: JSON.parse(localStorage.getItem('comeWay')),
			courseInfo: JSON.parse(localStorage.getItem('courseInfo')),
			selectCourseInfo: JSON.parse(localStorage.getItem('selectCourseInfo')),
			selectedDate: localStorage.getItem('selectedDate'),
			selectedTime: localStorage.getItem('selectedTime'),
			selectEvent: JSON.parse(localStorage.getItem('selectedEvent')),
			price: 0,
			selectYear: null,
			selectMonth: null,
			selectDate: null,
			selectDay: null,
			dayOfWeekStr: [ "日", "月", "火", "水", "木", "金", "土" ],
			paidInfo: false,
			companion_flg: false,
			movePlan: false,
			minor_flg: false,
			anim_flg: false,
			// 入力項目
			// men: localStorage.getItem("men"),
			// women: localStorage.getItem("women"),
			adult_num: localStorage.getItem("adult_num"),
			minor_num: this.minor_num = localStorage.getItem("minor_num"),
			alcohol_num:  localStorage.getItem("alcohol_num"),
			non_alcohol_num: localStorage.getItem("non_alcohol_num"),
			non_alcohol_flg: localStorage.getItem("non_alcohol_flg"),
			total_num: localStorage.getItem("total_num"),
			group_name: localStorage.getItem("group_name"),
			group_name_kana: localStorage.getItem("group_name_kana"),
			companion_name: null,
			companion_name_kana: null,
			pref: localStorage.getItem("pref"),
			prefName: null,
			tel: localStorage.getItem("tel"),
			email: localStorage.getItem("email"),
			count: this.count = localStorage.getItem("count"),
			countNum: null,
			visit: localStorage.getItem("visit"),
			visitWay: null,
			unit: localStorage.getItem("unit"),
			allergy: localStorage.getItem("allergy"),
			// note: localStorage.getItem("note"),
			url: "/api/reserve/save",
			// エラー
			errors: null,
			// データ
			data: null,
			modal_flg: false,
			faild_flg: false,
			reserved_flg: false
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'お客様情報確認'
			}
		},
		// link: [
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    // ],
    // script: [
    //   { type: 'text/javascript', src: '/include/include.js' }
    // ]
	},
	mounted() {
		window.$("body, html").scrollTop(0);

		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		// 20歳未満が参加できるか否か
		if(this.selectEvent.condition_minor_disallow_flg == 2) {
			this.minor_flg = true;
		}else {
			this.minor_flg = false;
		}

		// アニメーション初期設定
		lottie.loadAnimation({
			container: this.$refs.lottie,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
		});
		
		// 金額
		this.price = this.selectEvent.rsv_course_fee;
		// this.price = this.price.replace(/,/g, '');
		// this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(this.mygflg == true) {
				this.$router.push({ path: '/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = '宮城峡蒸溜所';
		}else {
			this.plantName = '余市蒸溜所';
		}
		this.$emit('updateHead');

		this.selectYear = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getFullYear();
		this.selectMonth = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getMonth() + 1;
		this.selectDate = new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDate();
		this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).getDay()];

		// 有料イベントかどうかの判別
		if(this.selectEvent.rsv_course_type_name.indexOf('有料') != -1) {
			this.paidInfo = true;
			if(this.total_num >= 2 && this.mygflg == false) {
				this.companion_flg = true;
			}
		}

		// 住所のnameを保存していたidを元に探す
		for(var i = 0; i < this.prefData.length; i++) {
			if(this.prefData[i].departure_id == this.pref) {
				this.prefName = this.prefData[i].departure_name;
			}
		}

		if(this.paidInfo == true && this.companion_flg == true) {
			this.companion_name = localStorage.getItem("companion_name");
			this.companion_name_kana = localStorage.getItem("companion_name_kana");
		}

		// this.coutの値を元にご来場回数のnameを探す
		for(var j = 0; j < this.visitNum.length; j++) {
			if(this.visitNum[j].vst_come_num_id == this.count) {
				this.countNum = this.visitNum[j].vst_come_num_name;
			}
		}

		// this.visitの値を元にご来場手段のnameを探す
		for(var k = 0; k < this.comeWay.length; k++) {
			if(this.comeWay[k].come_way_id == this.visit) {
				this.visitWay = this.comeWay[k].come_way_name;
			}
		}

		// 申し込んだイベントと来場手段によって送るデータが変わる＋新規予約か編集によっても変更になる
		if(this.companion_flg == false && this.unit == null || this.unit == 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}
				} else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit
						};
					}
				}
			}
		}else if(this.companion_flg == false && this.unit != null && this.unit != 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit
						};
					}
				}
			}
		}else if(this.companion_flg == true && this.unit == null || this.unit == 'null') {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}
				} else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					} else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit
						};
					}
				}
			}
		}else {
			if(localStorage.getItem('old_tel') != null) {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password'),
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							reservation_id: localStorage.getItem('resId'),
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							telno: localStorage.getItem('old_tel'),
							password: localStorage.getItem('password')
						};
					}
				}
			}else {
				if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							allergy_flg: this.allergy
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit,
							allergy_flg: this.allergy
						};
					}
				}else {
					if(this.non_alcohol_flg==1) {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit
						};
					}else {
						this.data = {
							plant_id: this.plant_id,
							course_id: this.selectEvent.rsv_course_id,
							date: this.selectedDate,
							time: this.selectedTime,
							group_name: this.group_name,
							group_name_kana: this.group_name_kana,
							// represent_note: this.note,
							start_place_cd: this.pref,
							represent_tel: this.tel,
							vst_mail_address: this.email,
							companion_name: this.companion_name,
							companion_name_kana: this.companion_name_kana,
							vst_come_num: this.count,
							total_num: this.total_num,
							adult_num: this.adult_num,
							alcohol_num: this.alcohol_num,
							non_alcohol_num: this.non_alcohol_num,
							// adult_men_num: this.men,
							// adult_female_num: this.women,
							minor_num: this.minor_num,
							come_way_cd: this.visit,
							come_way_num: this.unit
						};
					}
				}
			}
		}
	},
	methods: {
		retakeDate(time) {
			var slice = Number(time);
			var toString = String(slice);
			var slicer = ':' + toString.slice(-2) + '～';
			var result = toString.slice(0, -2) + slicer;
			return result;
		},

		backInput() {
			history.back();
		},

		sendForm() {
			window.$('.sendBtn').prop('disabled',true);
			// アニメーション出す
			this.anim_flg = true;

			axios({
				method: "post",
				url: this.url,
				timeout: 1500000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: this.data
			}).then(res => {
				this.anim_flg = false;
				window.$('.sendBtn').prop('disabled',false);
				
				// 予約IDの発行
				localStorage.setItem('password', res.data.data[0].password);

				// 結果ページに飛ぶ
				if(this.plant_id == 1000) {
					this.$router.push({ path: '/miyagikyo/reservation/thanks' });	
				}else {
					this.$router.push({ path: '/yoichi/reservation/thanks' });
				}
			}).catch(err => {
				this.anim_flg = false;
				console.log("▼▼▼▼err.response");
				console.log(err.response);
				// this.errors = err.response.data.data;
				// localStorage.setItem('errs', JSON.stringify(this.errors));
				// window.$('.sendBtn').prop('disabled', false);


				if (!err.response || err.response == undefined) {
					this.modal_flg = true;
					this.faild_flg = true;
					window.$('.sendBtn').prop('disabled', false);
				} else {
					this.errors = err.response.data.data;
					localStorage.setItem('errs', JSON.stringify(this.errors));
					window.$('.sendBtn').prop('disabled', false);

					if (this.errors[0].errcode == 400000 || this.errors[0].errcode == 400011 || this.errors[0].errcode == 400020 || this.errors[0].errcode == 400031 || this.errors[0].errcode == 400033 || this.errors[0].errcode == 400034 || this.errors[0].errcode == 400040 || this.errors[0].errcode == 400051 || this.errors[0].errcode == 400052 || this.errors[0].errcode == 400053 || this.errors[0].errcode == 400054 || this.errors[0].errcode == 400055 || this.errors[0].errcode == 400056 || this.errors[0].errcode == 400057 || this.errors[0].errcode == 400058 || this.errors[0].errcode == 400059) {
						history.back();
					}else if(this.errors[0].errcode == 400100) {
						this.modal_flg = true;
						this.reserved_flg = true;
					}else if(this.errors[0].errcode == '' || this.errors[0].errcode == null || this.errors[0].errcode == undefined || this.errors[0].errcode == 0) {
						this.modal_flg = true;
						this.faild_flg = true;
					}else {
						if(this.plant_id == 1000) {
							this.$router.push({ path: '/miyagikyo/reservation/error' });
						}else {
							this.$router.push({ path: '/yoichi/reservation/error' });
						}
					}

					
				}






				// if (err.response.data.data) {
				// 	console.log("pass-AAA");
				// 	this.errors = err.response.data.data;
				// 	localStorage.setItem('errs', JSON.stringify(this.errors));
					
				// 	if(this.errors[0].errcode == 400000 || this.errors[0].errcode == 400011 || this.errors[0].errcode == 400020 || this.errors[0].errcode == 400031 || this.errors[0].errcode == 400033 || this.errors[0].errcode == 400034 || this.errors[0].errcode == 400040 || this.errors[0].errcode == 400051 || this.errors[0].errcode == 400052 || this.errors[0].errcode == 400053 || this.errors[0].errcode == 400054 || this.errors[0].errcode == 400055 || this.errors[0].errcode == 400056 || this.errors[0].errcode == 400057 || this.errors[0].errcode == 400058 || this.errors[0].errcode == 400059) {
				// 		history.back();
				// 	}else if(this.errors[0].errcode == 400100) {
				// 		console.log("pass-BBB");
				// 		this.modal_flg = true;
				// 		this.reserved_flg = true;
				// 	}else if(this.errors[0].errcode == '' || this.errors[0].errcode == null || this.errors[0].errcode == undefined || this.errors[0].errcode == 0) {
				// 		console.log("pass-CCC");
				// 		this.modal_flg = true;
				// 		this.faild_flg = true;
				// 	}
				// } else if(err.response.data.data == undefined || !err.response.data.data) {
				// 	console.log("pass-DDD");
				// 	this.modal_flg = true;
				// 	this.faild_flg = true;
				// }else {
				// 	if(this.plant_id == 1000) {
				// 		this.$router.push({ path: '/miyagikyo/reservation/error' });
				// 	}else {
				// 		this.$router.push({ path: '/yoichi/reservation/error' });
				// 	}
				// }
			});
		},

		closeModal() {
			this.modal_flg = false;
			this.faild_flg = false;
			this.reserved_flg = false;
		},

		closeFaild() {
			this.modal_flg = false;
			this.faild_flg = false;
		},

		closeReserved() {
			this.modal_flg = false;
			this.reserved_flg = false;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.sendContent {
		width: 800px;
		margin: 0 auto;
		padding: 40px 0 60px;
		border-top: 1px solid #E8E8E8;
	}

	// 768以下
	.picxel .sendContent {
		width: calc(100% - 50px);
		padding: 40px 0;
		border-top: 1px solid #E8E8E8;
	}

	.redCareful {
		width: 100%;
		max-width: 800px;
		font-size: 24px;
		color: #FA141A;
		text-align: center;
		margin-top: 50px;
		padding: 21px 0 22px;
		background-color: #FFD6D6;
		border: 1px solid #FA141A;
		box-sizing: border-box;
	}

	// 768以下
	.picxel .redCareful {
		font-size: 18px;
		text-align: left;
		margin-top: 30px;
		padding: 15px 18px;
	}

	.win .mainFont .formSelect {
		margin-top: 33px;
		&_ttl {
			width: 290px;
			margin: 0 auto;
			padding-bottom: 15px;
			font-size: 34px;
			letter-spacing: 0.04em;
			text-align: center;
			font-weight: 100;
			border-bottom: 2px solid #af9754;
		}
		&_txt {
			font-size: 16px;
			line-height: 2 !important;
			margin-top: 30px;
		}
	}

	// 768以下
	.picxel.win .mainFont .formSelect {
		margin-top: 35px;
		&_ttl {
			width: 205px;
			font-size: 24px;
			padding-bottom: 15px;
			border-bottom: 2px solid #af9754;
		}
		&_txt {
			font-size: 11px;
			margin-top: 20px;
		}
	}

	// 入力確認
	.inputLists {
		background-color: #fafafa;
		width: 100%;
		margin-top: 77px;
		padding: 60px 95px 30px;
		list-style: none;
		box-sizing: border-box;
	}

	// 768以下
	.picxel .inputLists {
		margin-top: 51px;
		padding: 50px 15px 10px;
	}

	.inputList {
		margin-bottom: 30px;
		&_ttl {
			font-size: 16px;
			color: #AE9754;
			margin-bottom: 8px;
		}
		&_cont {
			font-size: 20px;
			font-weight: bold;
			line-height: 1.8 !important;
		}
		// &_note {
		// 	white-space: pre-wrap;
		// }
	}

	// 768以下
	.picxel .inputList {
		margin-bottom: 32px;
		&_ttl {
			font-size: 14px;
			margin-bottom: 8px;
		}
		&_cont {
			font-size: 16px;
		}
	}

	// コース
	.inputDate {
		display: flex;
		&_date {
			margin-right: 10px;
		}
	}

	// 768以下
	.picxel .inputDate {
		&_date {
			margin-right: 10px;
		}
	}

	// ご予約人数
	.peopleBox {
		// display: flex;
		// align-items: center;
		// justify-content: flex-start;
		// flex-wrap: wrap;
		// gap: 8px 67px;
		font-size: 18px;

	}
	.peopleBox > div{
		&:nth-child(n+2){
			margin-top: 10px;
		}
	}

	// 768以下
	.picxel .peopleBox {
		display: block;
		font-size: 16px;
	}

	.peopleMore {
		display: flex;
		align-items: center;
		&_ttl {
			font-size: 16px;
		}
		&_people {
			font-size: 20px;
			font-weight: bold;
			margin-left: 22px;
		}
	}

	// 768以下
	.picxel .peopleMore {
		display: block;
		&_ttl {
			margin-bottom: 5px;
		}
		&_people {
			display: inline-block;
			margin-left: 0;
			margin-right: 16px;
		}
	}

	.peopleBelow {
		display: flex;
		align-items: center;
		// margin-left: 67px;
		&_ttl {
			font-size: 16px;
		}
		&_less {
			font-size: 20px;
			font-weight: bold;
			margin-left: 22px;
		}
	}

	// 768以下
	.picxel .peopleBelow {
		display: block;
		margin-left: 0;
		margin-top: 16px;
		&_ttl {
			margin-bottom: 5px;
		}
		&_less {
			margin-left: 0;
		}
	}

	// 最終確認
	.win .mainFont .lastCheck {
		width: 100%;
		text-align: center;
		background-color: #E8E0C4;
		margin-top: 20px;
		padding: 40px 70px;
		box-sizing: border-box;
		&_lists {
			margin-bottom: 20px;
			li {
				font-size: 14px;
				line-height: 1.75 !important;
				text-align: left;
			}
		}
		&_txt {
			position: relative;
			display: inline-block;
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 1px;
				bottom: -15px;
				left: 0;
				background-size: 6px 1px;
				background-image: linear-gradient(to right, #AF9754, #AF9754 3px, transparent 3px, transparent 6px);
			}
			&_small {
				font-size: 16px;
			}
		}
	}
	.win .picxel .lastCheck {
		margin-top: 30px;
		padding: 40px 24px;
		&_lists {
			margin-bottom: 20px;
			li {
				font-size: 11px;
			}
		}
		&_txt {
			font-size: 14px;
			&_small {
				font-size: 11px;
			}
		}
	}

	.loadingBack {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #FFF;
		z-index: 9999;
	}

	#lottie {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 200px;
	}

	.picxel #lottie {
		width: 150px;
	}

	// ボタン
	.btns {
		width: 660px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		// 入力内容を変更するボタン
		.backBtn {
			font-size: 18px;
			line-height: 1 !important;
			color: #adadad;
			text-align: center;
			background: #fff;
			outline: none;
			padding: 22px 0;
			border: 1px solid #adadad;
			box-sizing: border-box;
			width: 320px;
			cursor: pointer;
		}
		// 予約を確定するボタン
		.sendBtn {
			position: relative;
			font-size: 18px;
			line-height: 1 !important;
			font-weight: bold;
			color: #fff;
			letter-spacing: 0.05em;
			border: none;
			outline: none;
			background-color: #AF9754;
			border: 1px solid #AF9754;
			padding: 22px 0;
			width: 320px;
			cursor: pointer;
			&:disabled {
				background-color: #e8e0c4;
				border-color: #e8e0c4;
			}
			&::after {
				content: '';
				width: 7px;
				height: 7px;
				border: 0px;
				border-top: solid 2px #fff;
				border-right: solid 2px #fff;
				position: absolute;
				top: 50%;
				right: 30px;
				transform: rotate(45deg)translateY(-50%);
				margin-top: -2px;
			}
		}
	}

	// 768以下
	.picxel .btns {
		width: 100%;
		display: block;
		margin: 20px auto 20px;
		// 入力内容を変更するボタン
		.backBtn {
			width: 100%;
			font-size: 15px;
			padding: 22px 0;
			margin-bottom: 20px;
			border: 1px solid #adadad;
		}
		// 予約を確定するボタン
		.sendBtn {
			width: 100%;
			font-size: 15px;
			padding: 22px 0;
			border: 1px solid #AF9754;
			&::after {
				width: 7px;
				height: 7px;
				right: 30px;
				border-top: solid 2px #fff;
				border-right: solid 2px #fff;
			}
		}
	}

	// 失敗モーダル関連
	.faildModal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		padding: 64px 100px 70px;
		background: #FFF;
		box-sizing: border-box;
		z-index: 999;
		.faildModal_ttl {
			font-size: 32px;
			text-align: center;
			margin-bottom: 33px;
		}
		.faildModal_txt {
			font-size: 16px;
			text-align: center;
			margin-bottom: 48px;
		}
		.faildModal_btn {
			display: block;
			width: 222px;
			font-size: 18px;
			text-align: center;
			color: #FFF;
			margin: 0 auto;
			padding: 16px;
			border: none;
			outline: none;
			background: #898989;
			box-sizing: border-box;
			cursor: pointer;
		}
	}

	.picxel .faildModal {
		width: calc(100% - 50px);
		padding: 64px 24px;
		.faildModal_ttl {
			font-size: 24px;
		}
		.faildModal_txt {
			font-size: 16px;
		}
		.faildModal_btn {
			max-width: 100%;
		}
	}

	.reservedModal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		padding: 64px 100px 70px;
		background: #FFF;
		box-sizing: border-box;
		z-index: 999;
		.reservedModal_ttl {
			font-size: 32px;
			color: #FF0000;
			text-align: center;
			margin-bottom: 33px;
		}
		.reservedModal_txt {
			font-size: 16px;
			text-align: center;
			margin-bottom: 48px;
		}
		.reservedModal_btn {
			display: block;
			width: 222px;
			font-size: 18px;
			text-align: center;
			color: #FFF;
			margin: 0 auto;
			padding: 16px;
			border: none;
			outline: none;
			background: #898989;
			box-sizing: border-box;
			cursor: pointer;
		}
	}

	.picxel .reservedModal {
		width: calc(100% - 50px);
		padding: 64px 24px;
		.reservedModal_ttl {
			font-size: 24px;
		}
		.reservedModal_txt {
			font-size: 16px;
			text-align: left;
		}
		.reservedModal_btn {
			max-width: 100%;
		}
	}

	// 失敗モーダル背景
	.faildBack {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: rgba($color: #000000, $alpha: .8);
		z-index: 99;
		cursor: pointer;
	}
</style>
