<template>
	<div class="maintenance">
		<h2 class="maintenance_ttl">ただいま<br class="sp">メンテナンス中です</h2>
		<p class="maintenance_txt">メンテナンス中のため一時サービスを停止しております。<br>大変ご不便をおかけいたしますが、今しばらくお待ちください。</p>
		<!-- <div class="maintenance_end">終了予定時刻：<br class="sp">2021年2月15日（月）17：00</div> -->
		<p class="maintenance_last">ご予約、その他のお問い合わせは<br class="pc">お手数ですがお電話でのご連絡をお願い申し上げます。</p>
		<Tel />
	</div>
</template>

<script>
import Tel from './Tel'

export default {
	name: 'Maintenance',
	components: {
		Tel
	},
	data () {
		return {
			plantName: null
		}
	},
	head: {
		title: function () {
			return {
				inner: this.plantName,
				separator: '|',
				complement: 'メンテナンス'
			}
		},
		// link: [
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    // ],
    // script: [
    //   { type: 'text/javascript', src: '/include/include.js' }
    // ]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1 || localStorage.getItem('plantId') == 1000) {
			this.plantName = '宮城峡蒸溜所';
		}else {
			this.plantName = '余市蒸溜所';
		}
		this.$emit('updateHead');
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.maintenance {
		padding-top: 80px;
		&_ttl {
			font-size: 40px;
			font-weight: 500;
			text-align: center;
			margin-bottom: 50px;
			letter-spacing: 0.05em;
			line-height: 1.5 !important;
		}
		&_txt {
			font-size: 18px;
			line-height: 2 !important;
			margin-bottom: 15px;
			text-align: center;
			letter-spacing: 0.05em;
		}
		&_end {
			width: 455px;
			box-sizing: border-box;
			font-size: 20px;
			letter-spacing: 0.03em;
			text-align: center;
			color: #DF1016;
			border: 1px solid #DF1016;
			border-radius: 6px;
			padding: 10px 0;
			margin: 0 auto 30px;
		}
		&_last {
			font-size: 18px;
			line-height: 2 !important;
			text-align: center;
			margin-bottom: 80px;
		}
	}

	// 768以下
	.picxel .maintenance {
		padding-top: 40px;
		&_ttl {
			width: calc(100% - 50px);
			font-size: 26px;
			margin: 0 auto 30px;
		}
		&_txt {
			width: calc(100% - 50px);
			margin: 0 auto 15px;
			font-size: 14px;
			text-align: left;
		}
		&_end {
			width: calc(100% - 50px);
			margin: 0 auto 15px;
			font-size: 16px;
			line-height: 1.8 !important;
			padding: 5px 20px;
		}
		&_last {
			width: calc(100% - 50px);
			margin: 0 auto 50px;
			font-size: 14px;
			text-align: left;
		}
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}
</style>
